import {
  Box,
  Center,
  Divider,
  Flex,
  Text,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function Results() {
  const navigate = useNavigate();
  const location = useLocation();
  const [secALevel, setSecALevel] = useState("yellow");
  const [secAScore, setSecAScore] = useState(0);
  const [secBLevel, setSecBLevel] = useState("green");
  const [secBScore, setSecBScore] = useState(0);
  const [secCLevel, setSecCLevel] = useState("green");
  const [secCScore, setSecCScore] = useState(0);
  const [overallLevel, setOverallLevel] = useState();
  const [overallScore, setOverallScore] = useState();
  const [toggleSpinner, setToggleSpinner] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setToggleSpinner(false);
    }, 2000);

    const { results } = location.state;
    const secAarr = results.slice(0, 7);
    const secBarr = results.slice(7, 14);
    const secCarr = results.slice(14);
    // console.log("A", secAarr);
    // console.log("B", secBarr);
    // console.log("C", secCarr);

    let secASum = 0;
    for (const item of secAarr) {
      secASum += Number(item);
    }

    let secBSum = 0;
    for (const item of secBarr) {
      secBSum += Number(item);
    }

    let secCSum = 0;
    for (const item of secCarr) {
      secCSum += Number(item);
    }

    setSecAScore(secASum);
    setSecBScore(secBSum);
    setSecCScore(secCSum);

    if (secASum <= 17) {
      setSecALevel("green");
    } else if (secASum >= 18 && secASum <= 29) {
      setSecALevel("yellow");
    } else {
      setSecALevel("red");
    }

    if (secBSum <= 5) {
      setSecBLevel("green");
    } else if (secBSum >= 6 && secBSum <= 11) {
      setSecBLevel("yellow");
    } else {
      setSecBLevel("red");
    }

    if (secCSum <= 33) {
      setSecCLevel("red");
    } else if (secCSum >= 34 && secCSum <= 39) {
      setSecCLevel("yellow");
    } else {
      setSecCLevel("green");
    }

    if (
      secALevel === "green" &&
      secBLevel === "green" &&
      secCLevel === "green"
    ) {
      setOverallLevel("green");
    } else if (
      //This part is giving an issue
      (secALevel === "yellow" ||
        secBLevel === "yellow" ||
        secCLevel === "yellow") &&
      !(secALevel === "red" || secBLevel === "red" || secCLevel === "red")
    ) {
      setOverallLevel("yellow");
    } else if (
      secALevel === "red" ||
      secBLevel === "red" ||
      secCLevel === "red"
    ) {
      setOverallLevel("red");
    }

    // setTimeout(() => {
    //console.log(secALevel, secBLevel, secCLevel, overallLevel);
    // }, 3000);
  });

  //0 -6, 7 questions
  //Emotional Exhaustion
  // <= 17 Low-level Burnout green
  // >= 18 and <= 29 Moderate Burnout yellow
  // >= 30 High-level Burnout re

  //7 - 13 7 questions
  //Depersonalization
  // <= 5 Low-level Burnout green
  // >= 6 and <= 11 Moderate Burnout yellow
  //>= 12 High-level Burnout red

  //14-21 8 questions
  //Personal acheivement
  // >= 40 Low-level Burnout green
  // >= 34 and <= 39 Moderate Burnout yellow
  //<= 33 High-level Burnout red

  //Overall
  //if all low-level then overall is low-level
  //if atleast one moderate and no high then moderate level
  //if atleast one High-level then overall is highlevel
  //total score is

  // Check if the user came from the correct route (/)
  // If not, navigate them back to the home page
  useEffect(() => {
    const cameFromHome = location.state && location.state.from === "/self-test";
    if (!cameFromHome) {
      navigate("/");
    }
  }, []);

  return (
    <Center h="100%" overflowY="scroll">
      {toggleSpinner ? (
        <Spinner
          mt="40%"
          size="xl"
          thickness="4px"
          color="green.500"
          speed="0.5s"
        />
      ) : (
        <Flex
          mt={{ base: "35%", sm: "10%", lg: "5%" }}
          flexDirection={"column"}
          textAlign={"center"}
          alignContent={"center"}
          justifyContent={"center"}
        >
          <Text
            fontWeight={"800"}
            fontSize={{ base: "16px", md: "20px" }}
            mb="10px"
          >
            You have
          </Text>
          <Center
            h={"100px"}
            w={{ base: "300px", md: "450px" }}
            p="10px"
            bg={overallLevel + ".400"}
            borderRadius={"18px"}
          >
            <Text
              color="white"
              alignContent={"center"}
              justifyContent={"center"}
              fontWeight={"800"}
              fontSize={{ base: "24px", md: "30px" }}
            >
              {overallLevel === "red"
                ? "High-level Burnout"
                : overallLevel === "yellow"
                ? "Moderate Burnout"
                : "Low-level Burnout"}
            </Text>
          </Center>
          <Center mt="8%" mb="8%">
            <Divider orientation="vertical" w="90%" h="2px" bg={"green.800"} />
          </Center>
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text fontWeight={"800"} fontSize={{ base: "20px", md: "25px" }}>
              Emotional Exhaustion
            </Text>
            <Center
              w={{ base: "50px", md: "75px" }}
              h={{ base: "50px", md: "75px" }}
              mt="15px"
              mb="30px"
              borderRadius={"50%"}
              borderColor={secALevel + ".400"}
              borderWidth={"5px"}
            >
              <Text fontWeight={"800"} fontSize={{ base: "24px", md: "30px" }}>
                {secAScore}
              </Text>
            </Center>
          </Flex>
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text fontWeight={"800"} fontSize={{ base: "20px", md: "25px" }}>
              Depersonalization
            </Text>
            <Center
              w={{ base: "50px", md: "75px" }}
              h={{ base: "50px", md: "75px" }}
              mt="15px"
              mb="30px"
              borderRadius={"50%"}
              borderColor={secBLevel + ".400"}
              borderWidth={"5px"}
            >
              <Text fontWeight={"800"} fontSize={{ base: "24px", md: "30px" }}>
                {secBScore}
              </Text>
            </Center>
          </Flex>
          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text fontWeight={"800"} fontSize={{ base: "20px", md: "25px" }}>
              Personal Achievement
            </Text>
            <Center
              w={{ base: "50px", md: "75px" }}
              h={{ base: "50px", md: "75px" }}
              mt="15px"
              mb="30px"
              borderRadius={"50%"}
              borderColor={secCLevel + ".400"}
              borderWidth={"5px"}
            >
              <Text fontWeight={"800"} fontSize={{ base: "24px", md: "30px" }}>
                {secCScore}
              </Text>
            </Center>
          </Flex>
          <Center>
            <Button
              bg="green.500"
              color="white"
              colorScheme="green"
              mt="5%"
              size={{ base: "md", md: "lg" }}
              onClick={() => {
                navigate("/");
              }}
            >
              Take Again
            </Button>
          </Center>
        </Flex>
      )}
    </Center>
  );
}

export default Results;
