import logo from "./logo.svg";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Landing from "./Pages/Landing";
import Test from "./Pages/Test";
import Results from "./Pages/Results";
import { Box } from "@chakra-ui/react";

function App() {
  return (
    <Box bg="green.100" w="100%" h="100vh">
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/self-test" element={<Test />} />
        <Route path="/results" element={<Results />} />
      </Routes>
    </Box>
  );
}

export default App;
