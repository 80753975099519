import {
  Box,
  Button,
  Radio,
  RadioGroup,
  Center,
  Flex,
  VStack,
  Text,
  Progress,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

function Test() {
  const navigate = useNavigate();
  const location = useLocation();

  const initialQuestionList = [
    "I feel emotionally drained by my work.",
    "Working with people all day long requires a great deal of effort.",
    "I feel like my work is breaking me down.",
    "I feel frustrated by my work.",
    "I feel I work too hard at my job.",
    "It stresses me too much to work in direct contact with people.",
    "I feel like I'm at the end of my rope.",
    "I feel I look after certain patients/clients impersonally, as if they are objects.",
    "I feel tired when I get up in the morning and have to face another day at work.",
    "I have the impression that my patients/clients make me responsible for some of their problems.",
    "I am at the end of my patience at the end of my work day.",
    "I really don't care about what  happens to some of my patients/clients.",
    "I have become more insensitive to people since I've been working.",
    "I'm afraid that this job is making me uncaring.",
    "I accomplish many worthwhile things in this job.",
    "I feel full of energy.",
    "I am easily able to understand what my patients/clients feel.",
    "I look after my patients' /clients' problems very effectively.",
    "In my work, I handle emotional problems very calmly.",
    "Through my work, I feel that I have a positive influence on people.",
    "I am easily able to create a relaxed atmosphere with my patients/clients.",
    "I feel refreshed when I have been close to my patients/clients at work.",
  ];

  const answerList = [
    { answer: "Never", value: "0" },
    { answer: "A Few Times per Year ", value: "1" },
    { answer: "Once a Month", value: "2" },
    { answer: "A Few Times per Month", value: "3" },
    { answer: "Once a Week", value: "4 " },
    { answer: "A Few Times per Week", value: " 5" },
    { answer: "Everyday", value: "6" },
  ];

  const [qNum, setQNum] = useState(0);
  const [questionList, setQuestionList] = useState(initialQuestionList);
  const [questionText, setQuestionText] = useState(questionList[qNum]);
  const [last, setLast] = useState(false);
  const [selectionValue, SetSelectionValue] = useState(-1);
  const [error, setError] = useState(false);
  const [results, setResults] = useState([]);
  const [progressValue, setProgressValue] = useState(0);

  const nextQuestion = () => {
    if (selectionValue === -1) {
      setError(true);
    } else {
      setError(false);
      let updatedResults = results;
      updatedResults.push(selectionValue);
      setResults(updatedResults);
      let newNum = qNum + 1;
      setQNum(newNum);
      setProgressValue(Math.round((newNum / questionList.length) * 100));

      //Reset Radio Selection
      SetSelectionValue(-1);
      //Change Question
      setQuestionText(questionList[newNum]);
      if (newNum === questionList.length - 1) {
        setLast(true);
      }
    }
  };

  const appendSpreadsheet = async (formData) => {
    //console.log(formData);
    // Replace with your Sheety API endpoint URL
    const sheetyApiUrl =
      "https://api.sheety.co/36fdca04ed296f9d10273de3b210c128/burnoutSelfTestData/sheet1";

    try {
      const data = { sheet1: formData };
      const response = await axios.post(sheetyApiUrl, data);

      if (response.status === 200) {
        // Data was successfully added to the sheet
        //console.log("Data added successfully:", response.data);
        // You can reset the form or perform any other actions here
      } else {
        console.error("Failed to add data:", response);
      }
    } catch (error) {
      console.error("Error adding data:", error);
    }
  };

  const submit = () => {
    if (selectionValue === -1) {
      setError(true);
    } else {
      setError(false);
      let updatedResults = results;
      updatedResults.push(selectionValue);
      setResults(updatedResults);
    }
    //console.log(results);

    const resultObject = {};
    for (let i = 0; i < results.length; i++) {
      resultObject["q" + (i + 1)] = results[i];
    }
    //console.log(resultObject);
    appendSpreadsheet(resultObject);

    navigate("/results", { state: { from: "/self-test", results: results } });
  };

  // Check if the user came from the correct route (/)
  // If not, navigate them back to the home page
  useEffect(() => {
    const cameFromHome = location.state && location.state.from === "/";
    if (!cameFromHome) {
      navigate("/");
    }
  }, []);

  //Next Button
  //Reset Value and change question text
  const NextButton = (
    <Button
      bg="green.500"
      colorScheme="green"
      color="white"
      mt="10%"
      size={{ base: "md", md: "lg" }}
      onClick={nextQuestion}
    >
      Next
    </Button>
  );

  //To Results Page
  const SubmitButton = (
    <Button
      bg="green.500"
      colorScheme="green"
      color="white"
      mt="10%"
      size={{ base: "md", md: "lg" }}
      onClick={submit}
    >
      Submit
    </Button>
  );

  return (
    <Center h="100%" overflowY="scroll">
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        w="70%"
      >
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          mt={{ base: "20%", md: "15%", lg: "10%" }}
        >
          <Text>
            {qNum + 1}/{questionList.length}
          </Text>

          <Progress
            mt="5%"
            value={progressValue}
            size="md"
            height="5px"
            colorScheme="green"
            w={"100%"}
          />

          <Text
            fontSize={{ base: "16px", md: "20px", lg: "24px" }}
            textAlign={"center"}
            w={{ base: "350px", md: "500px", lg: "650px" }}
            h="75px"
            mt={{ base: "25%", md: "15%" }}
          >
            {questionText}
          </Text>
          <RadioGroup
            onChange={SetSelectionValue}
            value={selectionValue}
            bg="rgb(255,255,255,0.5)"
            p="3%"
            mt="3%"
            borderRadius={"12px"}
            w={{ base: "300px", md: "450px", lg: "600px" }}
            backdropFilter="auto"
            backdropBlur="20px"
          >
            <VStack alignItems={"left"}>
              {answerList.map((a) => {
                return (
                  <Radio
                    colorScheme="green"
                    mt="1%"
                    value={a.value}
                    key={a.value}
                  >
                    {a.answer}
                  </Radio>
                );
              })}
            </VStack>
          </RadioGroup>
        </Flex>

        {last ? SubmitButton : NextButton}
        {error ? (
          <Text mt="2%" color={"red"} fontWeight={"600"} fontSize={"10px"}>
            Please Select One*
          </Text>
        ) : null}
      </Flex>
    </Center>
  );
}

export default Test;
