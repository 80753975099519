import {
  Box,
  Button,
  Center,
  Text,
  VStack,
  Flex,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import heartbeat from "../Assets/heartbeat.gif";
import { useRef } from "react";
import doctor from "../Assets/doctor.svg";

function Landing() {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  //   () => {
  //     navigate("/self-test", { state: { from: "/" } });
  // }

  return (
    <Center w="100%" h="100%" paddingBottom="10%" overflowY="scroll">
      <VStack w="70%">
        <Text
          textAlign={"center"}
          fontWeight={"800"}
          fontSize={{ base: "20px", md: "26px", lg: "28px" }}
          mt={{ base: "25%", md: "10%", lg: "0%" }}
          mb={"5%"}
        >
          Maslach Burnout Inventory Test
        </Text>
        <Text
          textAlign={"center"}
          fontSize={{ base: "16px", md: "18px", lg: "20px" }}
        >
          Medical students and healthcare professionals face high burnout rates
          which can lead to emotional exhaustion, impacting empathy and
          professionalism while affecting learners' professionalism resulting in
          poor performance and absenteeism.
        </Text>

        <Flex flexDirection="column" alignItems="center" mt="5%">
          <Text
            fontSize={{ base: "16px", md: "20px", lg: "24px" }}
            fontWeight={"800"}
            textAlign={"center"}
          >
            Take a self test to find out if you are at risk of burnout.
          </Text>
          <Button
            bg="green.500"
            color="white"
            colorScheme="green"
            mt="3%"
            size={{ base: "md", md: "lg" }}
            onClick={onOpen}
          >
            Start
          </Button>
        </Flex>
        <img
          style={{ width: "200px", marginTop: "10%" }}
          src={doctor}
          alt="doctor"
        />
      </VStack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        size={{ base: "xs", sm: "sm", md: "md" }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Disclaimer and Data Policy
            </AlertDialogHeader>

            <AlertDialogBody>
              <UnorderedList>
                <ListItem mb={"10px"}>
                  This test is not diagnostic and cannot replace a professional
                  assessment.{" "}
                </ListItem>

                <ListItem mb={"10px"}>
                  {" "}
                  Your involvement in this study is entirely optional, and
                  completing the survey should require approximately 5-6
                  minutes.{" "}
                </ListItem>
                <ListItem mb={"10px"}>
                  Your responses will remain anonymous and will be handled with
                  strict confidentiality.
                </ListItem>
                <ListItem mb={"10px"}>
                  If you choose to stop participating, your data will be
                  discarded.
                </ListItem>
              </UnorderedList>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme="green"
                onClick={() => {
                  navigate("/self-test", { state: { from: "/" } });
                }}
                ml={3}
              >
                I Agree
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Center>
  );
}

export default Landing;
